import { firestore, fromMillis, serverTimestamp } from "@lib/firebase"
import getVideoId from "get-video-id"
import { getNoembedInfo } from "@lib/utils/noembed"
import { getYouTubeCaptions } from "@lib/utils/youtube"

import { currentUserId, docUser } from "@lib/data/userData"
import {
    collectionUserPostReaction,
    getPostReactionsCollectionData,
} from "@lib/data/reactionData"

// ops
export function isPostOwner(post) {
    const uid = currentUserId()
    return uid === post?.data.uid
}

// doc
export function docUserPostRef(post) {
    if (typeof post === "string") {
        return firestore.doc(post)
    } else if (typeof post?.ref === "string") {
        return firestore.doc(post.ref)
    } else if (post?.ref) {
        return post.ref
    } else return post
}

export function docUserPost(pid, uid) {
    const postDoc = collectionUserPost(uid).doc(pid)
    return postDoc
}

// collection
export function collectionUserPost(uid) {
    const userDoc = docUser(uid)
    const postsCollection = userDoc.collection("posts")
    return postsCollection
}

export function collectionCurrentUserPost() {
    const uid = currentUserId()
    const postsCollection = collectionUserPost(uid)
    return postsCollection
}

export function collectionPost() {
    const postCollection = firestore.collectionGroup("posts")
    return postCollection
}

// query
export function queryUserPostCollection(
    userPostCollection,
    { deleted = false, limit = null } = {}
) {
    let userPostsQuery = userPostCollection
        .where("deleted", "==", deleted)
        .orderBy("createdAt", "desc")

    if (limit) {
        userPostsQuery = userPostsQuery.limit(limit)
    }

    return userPostsQuery
}

export function queryPostCollection(
    postCollection,
    {
        published = true,
        type = "user",
        visibility = "public",
        deleted = false,
        limit = null,
    } = {}
) {
    let postsQuery = postCollection
        .where("published", "==", published)
        .where("visibility", "==", visibility)
        .where("type", "==", type)
        .where("deleted", "==", deleted)
        .orderBy("createdAt", "desc")

    if (limit) {
        postsQuery = postsQuery.limit(limit)
    }

    return postsQuery
}

// get
export async function getUserPost(pid, uid) {
    const postCollection = collectionUserPost(uid)
    const postDoc = await postCollection.doc(pid).get()
    return postDoc
}

export async function getUserPostData(pid, uid, { json = false } = {}) {
    const converter = json ? postJSONConverter : postConverter
    const postCollection = collectionUserPost(uid).withConverter(converter)
    const postDoc = (await postCollection.doc(pid).get())?.data()
    return postDoc
}

export async function getUserPostCollectionData(
    uid,
    {
        published = true,
        type = "user",
        visibility = "public",
        deleted = false,
        limit = null,
        json = false,
    } = {}
) {
    const converter = json ? postJSONConverter : postConverter
    const postCollection = collectionUserPost(uid).withConverter(converter)

    const postsQuery = queryPostCollection(postCollection, {
        published,
        type,
        visibility,
        deleted,
        limit,
    })

    const posts = (await postsQuery.get()).docs.map((post) => post.data())
    return posts
}

export async function getPostCollectionData({
    published = true,
    type = "user",
    visibility = "public",
    deleted = false,
    limit = null,
    json = false,
} = {}) {
    const converter = json ? postJSONConverter : postConverter
    const postCollection = collectionPost().withConverter(converter)

    let postsQuery = queryPostCollection(postCollection, {
        published,
        type,
        visibility,
        deleted,
        limit,
    })

    const posts = (await postsQuery.get()).docs.map((post) => post.data())
    return posts
}

// generate
export async function genPostData(url) {
    const videoId = getVideoId(url)

    const contentId = videoId?.id ?? ""

    let transcript

    if (contentId && videoId?.service === "youtube") {
        try {
            transcript = await getYouTubeCaptions(contentId)
        } catch (error) {
            console.error(error)
        }
    }

    let contentInfo

    try {
        contentInfo = await getNoembedInfo(url)
    } catch (error) {
        console.error(error)
    }

    const contentUrl = contentInfo?.contentUrl ?? url

    const title = contentInfo?.title ?? ""
    const imageUrl = contentInfo?.imageUrl ?? ""
    const imageUrlSource = contentInfo?.imageUrlSource ?? ""

    const contentType = contentInfo?.contentType ?? ""
    const contentAuthorName = contentInfo?.contentAuthorName ?? ""
    const contentAuthorUrl = contentInfo?.contentAuthorUrl ?? ""

    const contentProviderName = contentInfo?.contentProviderName ?? ""
    const contentProviderUrl = contentInfo?.contentProviderUrl ?? ""

    const contentDuration = contentInfo?.contentDuration ?? 0

    transcript = transcript ?? []

    let icon = "ondemand_video"

    // Keep native controls for all but vimeo
    let nativeControls = !["vimeo"].includes(videoId?.service)

    // Tip: give all fields a default value here
    const data = {
        url: url,
        title: title,
        description: "",
        imageUrl: imageUrl,
        imageUrlSource: imageUrlSource,
        icon: icon,

        content: "",
        contentId: contentId,
        contentUrl: contentUrl,
        contentType: contentType,
        contentTitle: title,
        contentImageUrl: imageUrl,
        contentAuthorName: contentAuthorName,
        contentAuthorUrl: contentAuthorUrl,
        contentProviderName: contentProviderName,
        contentProviderUrl: contentProviderUrl,
        contentDuration: contentDuration,

        transcript: transcript,

        published: true,
        visibility: "public",
        featured: false,

        category: "",
        tags: [],

        allowReactions: true,
        nativeControls: nativeControls,
    }

    return data
}

// add
export async function addUserPost(data, username, uid, slug) {
    const postCollection = collectionUserPost(uid)
    const postDoc = postCollection.doc(slug)
    const pid = postDoc.id

    data = {
        ...data,
        pid: pid,
        uid: uid,

        posterName: username,

        type: "user",

        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),

        heartCount: 0,
        flagCount: 0,
        reactionCount: 0,

        deleted: false,
    }

    // Create a new post in firestore
    // TODO: add error handling
    await postDoc.set(data)

    return { ref: postDoc.ref, data: data }
}

export const cloneUserPost = async (postDoc, userData, slug, type) => {
    const batch = firestore.batch()

    const { uid, username, photoURL = null } = userData

    const userPostCollection = collectionUserPost(uid)

    const newPost = userPostCollection.doc(slug)
    const pid = newPost.id

    const sourcePost = await firestore.doc(postDoc.path).get()

    // Tip: give all fields a default value here
    const postData = {
        ...sourcePost.data(),

        pid: pid,

        uid: uid,
        posterName: username,

        published: true,
        visibility: "public",
        featured: false,

        type: type ?? "user",

        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),

        heartCount: 0,
        flagCount: 0,
        // reactionCount: 0,

        deleted: false,
    }

    // Create a new post in firestore
    // TODO: add error handling
    batch.set(newPost, postData)

    // reactions
    const userPostReactionCollection = collectionUserPostReaction(pid, uid)
    const sourcePostReactions = await getPostReactionsCollectionData(postDoc)

    sourcePostReactions.forEach((sourceReactionData) => {
        const newReaction = userPostReactionCollection.doc()

        const reactionData = {
            ...sourceReactionData.data,
            rid: newReaction.id,
            pid: pid,
            uid: uid,
            puid: uid,
            postRef: newPost,
            authorName: username,
            authorImageUrl: photoURL,
            posterName: username,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        }

        batch.set(newReaction, reactionData)
    })

    await batch.commit()

    return { ref: newPost.ref, data: postData }
}

// update
export const updateUserPost = async (data, postDoc) => {
    await postDoc.update({
        ...data,
        updatedAt: serverTimestamp(),
    })
    return true
}

// remove
export const removeUserPost = async (postDoc) => {
    await postDoc.update({
        deleted: true,
        updatedAt: serverTimestamp(),
    })

    return true
}

// converter

export const postConverter = {
    toFirestore: function (post) {
        return {
            ...post.data,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)
        return {
            ref: snapshot.ref,
            exists: snapshot.exists,
            data: { ...data },
        }
    },
}

export const postJSONConverter = {
    toFirestore: function (post) {
        let { createdAt, updatedAt } = post.data

        createdAt = typeof createdAt === "number" ? fromMillis(createdAt) : createdAt
        updatedAt = typeof updatedAt === "number" ? fromMillis(updatedAt) : updatedAt

        let data = {
            ...post.data,
            // Must convert back from milliseconds
            createdAt: createdAt,
            updatedAt: updatedAt,
        }
        return {
            ...data,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)
        data = {
            ...data,
            // Gotcha! firestore timestamp NOT serializable to JSON.
            // Must convert to milliseconds
            createdAt: data.createdAt?.toMillis() ?? null,
            updatedAt: data.updatedAt?.toMillis() ?? null,
        }
        return {
            ref: snapshot.ref.path,
            exists: snapshot.exists,
            data: { ...data },
        }
    },
}

// transformer for useDocumentData / useCollectionData
export function postTransformer(val) {
    return {
        data: val,
    }
}

export function postJSONTransformer(val) {
    val = {
        ...val,
        // Gotcha! firestore timestamp NOT serializable to JSON.
        // Must convert to milliseconds
        createdAt: val.createdAt?.toMillis() ?? null,
        updatedAt: val.updatedAt?.toMillis() ?? null,
    }
    return postTransformer(val)
}

export function optionsPost(json = false) {
    const transform = json ? postJSONTransformer : postTransformer
    const refField = json ? null : "ref"
    return {
        refField,
        transform,
    }
}
