import { firestore, auth, serverTimestamp, fromMillis } from "@lib/firebase"

export function currentUserId() {
    return auth.currentUser?.uid
}

export async function isUsernameTaken(username) {
    const usernameDoc = firestore.collection("usernames").doc(username)
    const { exists } = await usernameDoc.get()
    return exists
}

// doc
export function docUserRef(user) {
    if (typeof user === "string") {
        return firestore.doc(user)
    } else if (typeof user?.ref === "string") {
        return firestore.doc(user.ref)
    } else if (user?.ref) {
        return user.ref
    } else return user
}

export function docUser(uid) {
    const userDoc = firestore.collection("users").doc(uid)
    return userDoc
}

export function docCurrentUser() {
    const uid = currentUserId()
    const userDoc = docUser(uid)
    return userDoc
}

// get
export async function getUser(uid) {
    const usersDoc = docUser(uid)
    const user = await usersDoc.get()
    return user
}

export async function getUserWithUsername(username) {
    const usersDoc = firestore.collection("users")
    const query = usersDoc.where("username", "==", username).limit(1)
    const userDoc = (await query.get()).docs[0]
    return userDoc
}

export async function getUserDataWithUsername(username, { json = false } = {}) {
    const converter = json ? userJSONConverter : userConverter
    const usersCollection = firestore.collection("users").withConverter(converter)
    const query = usersCollection.where("username", "==", username).limit(1)
    const userDoc = (await query.get()).docs[0]
    return userDoc.data()
}

// update
export const updateUser = async (data, userDoc) => {
    await userDoc.update({
        ...data,
        updatedAt: serverTimestamp(),
    })
    return true
}

// delete

// converters
export const userConverter = {
    toFirestore: function (post) {
        return {
            ...post.data,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)
        return {
            ref: snapshot.ref,
            data: { ...data },
        }
    },
}

export const userJSONConverter = {
    toFirestore: function (post) {
        let { createdAt, updatedAt } = post.data

        createdAt = typeof createdAt === "number" ? fromMillis(createdAt) : createdAt
        updatedAt = typeof updatedAt === "number" ? fromMillis(updatedAt) : updatedAt

        let data = {
            ...post.data,
            // Must convert back from milliseconds
            createdAt: createdAt,
            updatedAt: updatedAt,
        }
        return {
            ...data,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)
        data = {
            ...data,
            // Gotcha! firestore timestamp NOT serializable to JSON.
            // Must convert to milliseconds
            createdAt: data.createdAt?.toMillis() ?? null,
            updatedAt: data.updatedAt?.toMillis() ?? null,
        }
        return {
            ref: snapshot.ref.path,
            data: { ...data },
        }
    },
}

// transformer for useDocumentData / useCollectionData
export function userTransformer(val) {
    return {
        data: val,
    }
}

export function userJSONTransformer(val) {
    val = {
        ...val,
        // Gotcha! firestore timestamp NOT serializable to JSON.
        // Must convert to milliseconds
        createdAt: val.createdAt?.toMillis() ?? null,
        updatedAt: val.updatedAt?.toMillis() ?? null,
    }
    return userTransformer(val)
}

export function optionsUser(json = false) {
    const transform = json ? userJSONTransformer : userTransformer
    const refField = json ? null : "ref"
    return {
        refField,
        transform,
    }
}
