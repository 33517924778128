import React from "react"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

// Icons
import TwitterIcon from "@material-ui/icons/Twitter"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

// Components
import Typography from "@components/Typography"
import Link from "@components/Link"

// Forms
import SubscribeForm from "@forms/SubscribeForm"

function Copyright() {
    return (
        <React.Fragment>
            {"© "}
            <Link color="textPrimary" href="https://s-k-i-p.com/">
                s-k-i-p
            </Link>{" "}
            {new Date().getFullYear()}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        minWidth: "350px",
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        display: "flex",
    },
    iconsWrapper: {
        height: 120,
    },
    icons: {
        display: "flex",
    },
    iconLink: {
        width: 48,
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        marginRight: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    icon: {
        color: "white",
    },
    list: {
        margin: 0,
        listStyle: "none",
        padding: 0,
    },
    listItem: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    language: {
        marginTop: theme.spacing(1),
        width: 150,
    },
}))

const LANGUAGES = [
    {
        code: "en-US",
        name: "English",
    },
]

export default function AppFooter() {
    const classes = useStyles()

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.only("sm"), {
        defaultMatches: true,
    })

    return (
        <Typography component="footer" className={classes.root}>
            <Container className={classes.container}>
                <Grid container justifyContent="space-between" spacing={1}>
                    <Grid item xs={12} sm={12} md={2}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-end"
                            className={classes.iconsWrapper}
                            spacing={2}
                        >
                            <Grid item className={classes.icons}>
                                <Link
                                    href="https://twitter.com/sttgbit"
                                    className={classes.iconLink}
                                >
                                    <TwitterIcon className={classes.icon} />
                                </Link>
                                <Link
                                    href="https://linkedin.com/company/sttgbit"
                                    className={classes.iconLink}
                                >
                                    <LinkedInIcon className={classes.icon} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Copyright />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item styles={{ flex: 1 }}></Grid> */}
                    <Grid
                        item
                        container
                        xs={12}
                        sm={12}
                        md={10}
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Grid item xs={6} sm={3} md={2} style={{ order: 1 }}>
                            <Typography variant="h6" marked="left" gutterBottom>
                                About
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/about/"
                                    >
                                        About
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/team/"
                                    >
                                        Team
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/pricing/"
                                    >
                                        Pricing
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/faq/"
                                    >
                                        FAQ
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs={6} sm={3} md={2} style={{ order: 2 }}>
                            <Typography variant="h6" marked="left" gutterBottom>
                                Legal
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/code/"
                                    >
                                        Code of Conduct
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/terms/"
                                    >
                                        Terms
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="/privacy/"
                                    >
                                        Privacy
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                            style={{ order: isSmall ? 4 : 3 }}
                        >
                            <Typography variant="h6" marked="left" gutterBottom>
                                Resources
                            </Typography>
                            <ul className={classes.list}>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="https://hotjar.com"
                                    >
                                        Analytics by hotjar
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="https://usefathom.com"
                                    >
                                        Analytics by fathom
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="https://sentry.io"
                                    >
                                        Crash reports by Sentry
                                    </Typography>
                                </li>
                                <li className={classes.listItem}>
                                    <Typography
                                        component={Link}
                                        color="textPrimary"
                                        href="https://www.freepik.com/"
                                    >
                                        Some Icons by Freepik
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={5}
                            style={{ order: isSmall ? 3 : 4 }}
                        >
                            <Typography variant="h6" marked="left" gutterBottom>
                                Newsletter
                            </Typography>
                            <SubscribeForm formId={2646457} />
                            {/* <script
                                async
                                data-uid="0b8543914a"
                                src="https://sttgbit.ck.page/0b8543914a/index.js"
                            ></script> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    )
}
