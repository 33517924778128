import React from "react"

// Material UI
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"

const styles = (theme) => ({
    root: {
        borderRadius: 150,
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.h1.fontFamily,
        padding: theme.spacing(0, 0),
        //padding: theme.spacing(0, 0.5),
        fontSize: theme.typography.pxToRem(14),
        boxShadow: "none",
        "&:active, &:focus": {
            boxShadow: "none",
        },
    },
    sizeSmall: {
        padding: theme.spacing(0, 0),
        fontSize: theme.typography.pxToRem(13),
    },
})

export default withStyles(styles)(IconButton)
