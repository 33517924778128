/* eslint-disable no-undef */
import React from "react"
import { Toaster } from "react-hot-toast"
import PropTypes from "prop-types"
import { useRouter } from "next/router"

// Material-ui
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

// Views
import AppFooter from "@views/AppFooter"
import AppNavBar from "@views/AppNavBar"

// Components
import ScrollTop from "@components/ScrollTop"

// Lib
import { UserContext } from "@lib/context/UserContext"
import useUserData from "@lib/hooks/useUserData"
import { initializeHotjar } from "@lib/hotjar"
// import * as Fathom from "fathom-client"

import theme from "../themes/theme"

export default function MyApp(props) {
    const { Component, pageProps } = props

    const userData = useUserData()
    const router = useRouter()

    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side")
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
        // Initialize Hotjar analytics
        initializeHotjar()
    }, [])

    // React.useEffect(() => {
    //     // Initialize Fathom when the app loads
    //     // Example: yourdomain.com
    //     //  - Do not include https://
    //     //  - This must be an exact match of your domain.
    //     //  - If you're using www. for your domain, make sure you include that here.
    //     Fathom.load(process.env.NEXT_PUBLIC_FATHOM_TRACKING_CODE, {
    //         includedDomains: ["s-k-i-p.com", "www.s-k-i-p.com"],
    //         url: ["https://dragon.s-k-i-p.com/script.js"],
    //     })

    //     function onRouteChangeComplete() {
    //         Fathom.trackPageview()
    //     }
    //     // Record a pageview when route changes
    //     router.events.on("routeChangeComplete", onRouteChangeComplete)

    //     // Unassign event listener
    //     return () => {
    //         router.events.off("routeChangeComplete", onRouteChangeComplete)
    //     }
    // }, [])

    const layout = (page) => {
        return (
            <UserContext.Provider value={userData}>
                <AppNavBar />
                {page}
                <Toaster />
                <ScrollTop />
                <AppFooter />
            </UserContext.Provider>
        )
    }
    const getLayout = Component.getLayout || layout

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                {getLayout(<Component {...pageProps} />)}
            </ThemeProvider>
        </React.Fragment>
    )
}

MyApp.propTypes = {
    Component: PropTypes.elementType.isRequired,
    pageProps: PropTypes.object.isRequired,
}
