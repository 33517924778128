import React, { useRef } from "react"
import { useFormContext, Controller } from "react-hook-form"

// Material UI
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        transition: theme.transitions.create("width"),
        width: "100%",
    },
    inputLabel: {},
}))

function FormInput(props) {
    const classes = useStyles()
    const { control } = useFormContext()
    const {
        name,
        select = false,
        options,
        label,
        helperText,
        required,
        inputRef = useRef(null),
        startAdornment,
        endAdornment,
        autoComplete = "new-password",
        errorDisplay = "helperText",
        inputComponent,
        noEnter = false,
        blurOnEnter = noEnter,
        readOnly = false,
        sideEffect,
        inputType = "search",
        ...others
    } = props

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({
                field: { ref, onChange, ...otherField },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isValidating },
            }) => (
                <TextField
                    select={select}
                    inputRef={(e) => {
                        ref(e)
                        if (inputRef) inputRef.current = e
                    }}
                    onChange={(e) => {
                        if (sideEffect) sideEffect(e)
                        onChange(e)
                    }}
                    {...otherField}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            if (noEnter) e.preventDefault()
                            if (blurOnEnter && inputRef) inputRef.current.blur()
                        }
                    }}
                    label={
                        errorDisplay === "label"
                            ? isValidating
                                ? "Checking..."
                                : invalid
                                ? error?.message
                                : label
                            : label
                    }
                    helperText={
                        errorDisplay === "helperText"
                            ? isValidating
                                ? "Checking..."
                                : invalid
                                ? error?.message
                                : helperText
                            : helperText
                    }
                    error={invalid}
                    InputLabelProps={{
                        required: required || false,
                        shrink: true,
                        className: classes.inputLabel,
                    }}
                    InputProps={{
                        type: inputType,
                        classes: {
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        },
                        startAdornment: startAdornment && (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ),
                        endAdornment: endAdornment && (
                            <InputAdornment position="end">{endAdornment}</InputAdornment>
                        ),
                        inputComponent: inputComponent,
                        autoComplete: autoComplete,
                        readOnly: readOnly,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    {...others}
                >
                    {select &&
                        options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                </TextField>
            )}
        />
    )
}

export default FormInput
