import React, { useContext, useMemo } from "react"

// Next
import { useRouter } from "next/router"

// Material UI
import { useTheme, makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import MenuItem from "@material-ui/core/MenuItem"
import Menu from "@material-ui/core/Menu"
import Avatar from "@material-ui/core/Avatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Grid from "@material-ui/core/Grid"

// Icons
import AccountCircle from "@material-ui/icons/AccountCircle"
import MenuIcon from "@material-ui/icons/Menu"
import SkipNext from "@material-ui/icons/SkipNext"
import PersonIcon from "@material-ui/icons/Person"
import BookmarksIcon from "@material-ui/icons/Bookmarks"
import PeopleAltIcon from "@material-ui/icons/PeopleAlt"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"

// Components
import AppBar from "@components/AppBar"
import Button from "@components/Button"
import IconButton from "@components/IconButton"
import Toolbar from "@components/Toolbar"
import StyledLink from "@components/Link"
import JumpToAnchorText from "@components/JumpToAnchorText"

// Forms
import PostCreateForm from "@forms/PostCreateForm"

// Lib
import { UserContext } from "@lib/context/UserContext"
import { auth } from "@lib/firebase"
import { stringToColor } from "@lib/utils/color"

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        marginTop: "15px",
        marginBottom: "15px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px",
            marginBottom: "10px",
        },
        alignContent: "center",
        justifyContent: "space-between",
        minWidth: "350px",
    },
    title: {
        display: "block",
        minWidth: "110px",
        [theme.breakpoints.down("xs")]: {
            minWidth: "100px",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        // marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
        // [theme.breakpoints.only("xs")]: {
        //     width: "50%",
        // },
    },
    avatar: ({ name = "default" }) => ({
        color: theme.palette.getContrastText(stringToColor(name)),
        backgroundColor: stringToColor(name),
    }),
    signupButton: {
        boxShadow:
            "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
        minWidth: "200px",
        fontSize: "16px",
        //marginRight: "20px",
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up(780)]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up(780)]: {
            display: "none",
        },
    },
}))

export default function AppNavBar() {
    //const { classes } = props

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

    const { username, status, data } = useContext(UserContext)

    const classes = useStyles({ name: username ?? "default" })

    const avatar = data?.photoURL || "https://"

    const authorized = status === "complete"

    const router = useRouter()

    const isIndex = useMemo(() => {
        return router.pathname === "/"
    }, [router.asPath])

    const signOut = () => {
        handleMenuClose()
        auth.signOut()
        router.reload()
    }

    const menuGo = (destination) => {
        handleMenuClose()
        router.push(destination)
    }

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"), {
        defaultMatches: true,
    })

    const joinBeta = (
        <Grid container spacing={0} direction="column" alignItems="center">
            <Grid item>
                <Button
                    startIcon={<PersonIcon style={{ fontSize: "30px" }} />}
                    color="secondary"
                    variant="contained"
                    component={StyledLink}
                    href="/beta"
                    className={classes.signupButton}
                >
                    Login / Sign-up
                </Button>
            </Grid>
            <Grid item>
                <Typography align="center" color="primary" variant="caption">
                    No Email or Credit Card Required
                </Typography>
            </Grid>
        </Grid>
    )

    const menuId = "primary-search-account-menu"
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => menuGo(`/u/${username}`)}>Profile</MenuItem>
            <MenuItem onClick={() => menuGo(`/account`)}>Account</MenuItem>
            <MenuItem onClick={() => menuGo(`/demo`)}>Demo</MenuItem>
            <MenuItem onClick={signOut}>Log out</MenuItem>
        </Menu>
    )

    const mobileMenuId = "primary-search-account-menu-mobile"
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {!authorized && [
                <MenuItem key={1} onClick={() => menuGo("/how-it-works")}>
                    <ListItemIcon aria-label="join">
                        <HelpOutlineIcon color="inherit" />
                    </ListItemIcon>
                    <Typography>How it works</Typography>
                </MenuItem>,
                <MenuItem key={2} onClick={() => menuGo("/used-by")}>
                    <ListItemIcon aria-label="join">
                        <PeopleAltIcon color="inherit" />
                    </ListItemIcon>
                    <Typography>Who uses it</Typography>
                </MenuItem>,
                <MenuItem key={3} onClick={() => menuGo("/pricing")}>
                    <ListItemIcon aria-label="join">
                        <AttachMoneyIcon color="inherit" />
                    </ListItemIcon>
                    <Typography>Pricing</Typography>
                </MenuItem>,
                <MenuItem key={4} onClick={() => menuGo("/beta")}>
                    <ListItemIcon aria-label="join">
                        <PersonIcon color="inherit" />
                    </ListItemIcon>
                    <Typography>Login / Sign-up</Typography>
                </MenuItem>,
            ]}
            {authorized && [
                <MenuItem key={1} onClick={() => menuGo("/marks")}>
                    <ListItemIcon aria-label="marks">
                        <BookmarksIcon color="inherit" />
                    </ListItemIcon>
                    <Typography>Your Marks</Typography>
                </MenuItem>,
                <MenuItem key={2} onClick={() => menuGo("/s")}>
                    <ListItemIcon aria-label="skips">
                        <SkipNext color="inherit" />
                    </ListItemIcon>
                    <Typography>All Skips</Typography>
                </MenuItem>,
                <MenuItem key={3} onClick={handleProfileMenuOpen}>
                    <ListItemIcon
                        aria-label="account of current user"
                        aria-controls="primary-search-account-menu"
                        aria-haspopup="true"
                    >
                        <AccountCircle color="inherit" />
                    </ListItemIcon>
                    <Typography>User Menu</Typography>
                </MenuItem>,
            ]}
        </Menu>
    )

    return (
        <div id="back-to-top-anchor" className={classes.grow}>
            <AppBar position="static">
                <Toolbar className={classes.toolbar}>
                    {/* <Tooltip title={"(Beta)"} arrow placement="bottom"> */}
                    <Typography
                        color="textPrimary"
                        component={StyledLink}
                        href="/"
                        className={classes.title}
                        variant={"h4"}
                        noWrap
                    >
                        S-K-I-P
                    </Typography>
                    {/* </Tooltip> */}
                    <div className={classes.search}>
                        {!authorized && isIndex && (
                            <Grid
                                container
                                className={classes.sectionDesktop}
                                justifyContent={"center"}
                                spacing={isSmall ? 3 : 5}
                            >
                                <Grid item>
                                    <JumpToAnchorText
                                        noWrap
                                        variant={"h5"}
                                        anchorId={"#how-it-works"}
                                        color={"primary"}
                                    >
                                        How it works
                                    </JumpToAnchorText>
                                </Grid>
                                <Grid item>
                                    <JumpToAnchorText
                                        noWrap
                                        variant={"h5"}
                                        anchorId={"#used-by"}
                                        color={"primary"}
                                    >
                                        Used by
                                    </JumpToAnchorText>
                                </Grid>
                                <Grid item>
                                    <JumpToAnchorText
                                        noWrap
                                        variant={"h5"}
                                        anchorId={"#pricing"}
                                        color={"primary"}
                                    >
                                        Pricing
                                    </JumpToAnchorText>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        noWrap
                                        variant={"h5"}
                                        href={"/faq"}
                                        component={StyledLink}
                                    >
                                        Feedback
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {authorized && <PostCreateForm />}
                    </div>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {!authorized && joinBeta}
                        {authorized && (
                            <>
                                <Tooltip title={"your marks"} arrow placement="bottom">
                                    <IconButton
                                        aria-label="your marks"
                                        color="inherit"
                                        component={StyledLink}
                                        href="/marks"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        <BookmarksIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"all skips"} arrow placement="bottom">
                                    <IconButton
                                        aria-label="all skips"
                                        color="inherit"
                                        component={StyledLink}
                                        href={"/s"}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        <SkipNext />
                                    </IconButton>
                                </Tooltip>
                                <Button
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                    size="small"
                                >
                                    <Avatar
                                        src={avatar}
                                        alt={username}
                                        className={classes.avatar}
                                    ></Avatar>
                                </Button>
                            </>
                        )}
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                            style={{ marginLeft: "5px" }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    )
}
