/* eslint-disable no-undef */
import React, { useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"

import toast from "react-hot-toast"

// Components
import Typography from "@components/Typography"

import IconButton from "@components/IconButton"

// Icons
import EmailIcon from "@material-ui/icons/Email"

// Material UI
import Tooltip from "@material-ui/core/Tooltip"
import Grid from "@material-ui/core/Grid"

// Lib

// Forms
import FormInput from "@forms/input/FormInput"
import FormCheckbox from "@forms/input/FormCheckbox"

// Validation
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const toastOpts = {
    style: {
        minWidth: "150px",
    },
    success: {
        duration: 3000,
        icon: "🔥",
    },
    error: {
        duration: 3000,
        icon: "😭",
    },
}

const schema = yup.object().shape({
    email: yup.string().trim().lowercase().email("Needs to be a valid email").required(),
    consent: yup
        .boolean()
        .oneOf([true], "You need to consent to receiving emails")
        .required(),
})

function SubscribeForm(props) {
    const { formId } = props
    const defaultValues = { email: "", consent: false }

    const apiKey = process.env.NEXT_PUBLIC_CONVERTKIT_API_KEY
    const apiUrl = process.env.NEXT_PUBLIC_CONVERTKIT_API_URL
    const requestUrl = `${apiUrl}forms/${formId}/subscribe`

    const methods = useForm({
        defaultValues,
        mode: "onSubmit",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
    })

    const { handleSubmit, reset, watch, formState } = methods

    const { isValid, isDirty, errors, isSubmitSuccessful } = formState

    const onSubmit = async ({ email, consent }) => {
        if (email && consent) {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email,
                    tags: [2576265],
                    api_key: apiKey,
                }),
            }

            const sendingLink = fetch(requestUrl, requestOptions)
            toast.promise(
                sendingLink,
                {
                    loading: "Subscribing your email...",
                    success: "Subscription sent!",
                    error: "Could not send!",
                },
                toastOpts
            )
            sendingLink
                .then((response) => response.json())
                .catch((error) => {
                    var errorCode = error.code
                    var errorMessage = error.message
                    console.log(errorCode, errorMessage)
                })
        }
    }

    // useEffect(() => {
    //     if (formState.isSubmitSuccessful) {
    //         reset()
    //     }
    // }, [formState, reset])

    return (
        <>
            {isSubmitSuccessful ? (
                <Typography color="secondary">
                    {"Thank you for signing up! " +
                        "Please go to your email (spam, promotion folder) to confirm your subscription. " +
                        "Otherwise we won't be able to get in touch!"}
                </Typography>
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        <Grid container justifyContent={"flex-start"} spacing={0}>
                            <Grid item xs={10}>
                                <FormInput
                                    fullWidth
                                    name="email"
                                    margin="dense"
                                    variant="outlined"
                                    placeholder={"Type your email here..."}
                                    errorDisplay={"label"}
                                    endAdornment={
                                        <Tooltip
                                            title={"subscribe!"}
                                            arrow
                                            placement="top"
                                        >
                                            <IconButton type="submit">
                                                <EmailIcon size="small" color="primary" />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCheckbox
                                    name="consent"
                                    label={
                                        "Yes, I would like to receive updates via email"
                                    }
                                    variant="subtitle2"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">
                                    {"We respect your privacy. Unsubscribe at any time."}
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            )}
        </>
    )
}

export default SubscribeForm
