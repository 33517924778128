import React, { useContext, useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { useRouter } from "next/router"

import ReactPlayer from "react-player"
import toast from "react-hot-toast"

// Components
import IconButton from "@components/IconButton"

// MaterialUI
import Tooltip from "@material-ui/core/Tooltip"

// Icons
import SubscriptionsIcon from "@material-ui/icons/Subscriptions"

// Lib
import { UserContext } from "@lib/context/UserContext"
import { genPostData, addUserPost } from "@lib/data/postData"

// Forms
import FormInput from "@forms/input/FormInput"

// Validation
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

const schema = yup.object().shape({
    link: yup
        .string()
        .trim()
        .url()
        .required("Try your favorite cat video...")
        .test("canPlay", "Currently unsupported source", function (value) {
            return value ? ReactPlayer.canPlay(value) : true
        }),
})

const toastOpts = {
    style: {
        minWidth: "150px",
    },
    success: {
        duration: 3000,
        icon: "🔥",
    },
    error: {
        duration: 3000,
        icon: "😭",
    },
}

function PostCreateForm(props) {
    const defaultValues = { link: "" }

    const methods = useForm({
        defaultValues,
        mode: "onSubmit",
        reValidateMode: "onBlur",
        resolver: yupResolver(schema),
    })

    const { handleSubmit, reset, watch, formState } = methods

    const { isValid, isDirty, errors } = formState

    const router = useRouter()

    const { user, username } = useContext(UserContext)

    const onSubmit = async ({ link }) => {
        if (link) {
            const generatingData = genPostData(link)
            toast.promise(
                generatingData,
                {
                    loading: "Generating post...",
                    success: "Post ready!",
                    error: "Could not generate.",
                },
                toastOpts
            )

            const data = await generatingData
            const post = await addUserPost(data, username, user.uid)

            router.push(`/s/${username}/${post.data.pid}/`)
        } else {
            router.push(`/`)
        }
    }

    useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset()
        }
    }, [formState, reset])

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <FormInput
                    fullWidth
                    name="link"
                    margin="dense"
                    variant="outlined"
                    placeholder={"Paste a link i.e. YouTube, Vimeo, Podcast (MP3)..."}
                    errorDisplay={"label"}
                    label={"skip to the good bit..."}
                    helperText={""}
                    endAdornment={
                        <Tooltip title={"load link"} arrow placement="bottom">
                            <IconButton type="submit">
                                <SubscriptionsIcon size="small" color="action" />
                            </IconButton>
                        </Tooltip>
                    }
                />
            </form>
        </FormProvider>
    )
}

export default PostCreateForm
