import axios from "axios"
import xml2js from "xml2js"
import qs from "query-string"

function parseYouTubeInfo(response) {
    let parsedVideoInfo = qs.parse(response?.data)

    if (parsedVideoInfo.status === "fail") return {}

    parsedVideoInfo.player_response = JSON.parse(parsedVideoInfo.player_response)

    return parsedVideoInfo
}

export async function fetchYouTubeInfo(videoID) {
    const response = await axios.get(
        `https://youtube.com/get_video_info?video_id=${videoID}`
    )
    return parseYouTubeInfo(response)
}

export async function getYouTubeInfo(videoID) {
    const parsedVideoInfo = await fetchYouTubeInfo(videoID)

    const playerResponse = parsedVideoInfo.player_response
    const videoDetails = playerResponse?.videoDetails
    const generalDetails = playerResponse?.microformat.playerMicroformatRenderer
    const captions = playerResponse?.captions?.playerCaptionsTracklistRenderer

    const response = {
        videoId: videoDetails?.videoId || null,
        contentDuration: videoDetails?.lengthSeconds || null,

        // NoEmbed equivalent
        contentUrl: parsedVideoInfo?.video_url || null,

        title: videoDetails?.title || null,
        imageUrl:
            videoDetails?.thumbnail.thumbnails.find((t) =>
                t.url.endsWith("maxresdefault.jpg")
            )?.url ||
            videoDetails?.thumbnail?.thumbnails[0].url ||
            null,
        imageUrlSource: "youtube",

        contentType: "video",
        contentAuthorName: videoDetails?.author || null,
        contentAuthorUrl: generalDetails?.ownerProfileUrl || null,

        contentProviderName: "youtube",
        contentProviderUrl: "https://youtube.com/",

        // Extra
        contentChannelId: videoDetails?.channelId || null,
        contentChannelName: generalDetails?.ownerChannelName || null,

        shortDescription: videoDetails?.shortDescription || null,
        description: generalDetails?.description.simpleText || null,

        keywords: videoDetails?.keywords || null,
        category: generalDetails?.category || null,

        uploadDate: generalDetails?.uploadDate || null,

        // Captions
        captionsTracks:
            captions?.captionTracks.map((ct) => ({
                baseUrl: ct.baseUrl || null,
                lang: ct.languageCode || null,
            })) || null,
    }

    return response
}

function parseYouTubeTranscript(response) {
    let lines
    xml2js.parseString(response?.data, (err, result) => {
        if (!err) {
            lines = result?.transcript.text.map(({ _, $ }) => ({ ...$, text: _ }))
        }
    })

    return lines ?? []
}

export async function getYouTubeCaptionInfo(videoID) {
    const parsedVideoInfo = await fetchYouTubeInfo(videoID)

    const playerResponse = parsedVideoInfo.player_response
    const captions = playerResponse?.captions?.playerCaptionsTracklistRenderer

    const response = {
        captionsTracks:
            captions?.captionTracks.map((ct) => ({
                baseUrl: ct.baseUrl || null,
                lang: ct.languageCode || null,
            })) || null,
    }

    return response
}

export async function getYouTubeSubtitles(videoID, lang = "en", videoInfo) {
    videoInfo = videoInfo ?? (await getYouTubeCaptionInfo(videoID))

    const captionTrack = videoInfo?.captionsTracks[lang]

    let response

    if (captionTrack) {
        response = await axios.get(captionTrack.baseUrl)
    }

    return parseYouTubeTranscript(response)
}

export async function getYouTubeCaptions(videoID, lang = "en") {
    const response = await axios.get(
        `https://video.google.com/timedtext?v=${videoID}&lang=${lang}`
    )
    return parseYouTubeTranscript(response)
}
