import React from "react"

// Material UI
import { withStyles } from "@material-ui/core/styles"
import MuiButton from "@material-ui/core/Button"

const styles = (theme) => ({
    root: {
        borderRadius: 0,
        fontWeight: theme.typography.fontWeightMedium,
        fontFamily: theme.typography.h1.fontFamily,
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.pxToRem(13),
        boxShadow: "none",
        "&:active, &:focus": {
            boxShadow: "none",
        },
    },
    sizeSmall: {
        padding: theme.spacing(0, 1),
        fontSize: theme.typography.pxToRem(13),
    },
    sizeLarge: {
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.pxToRem(16),
    },
})

const Button = React.forwardRef(function Button(props, ref) {
    return <MuiButton ref={ref} {...props} />
})

export default withStyles(styles)(Button)
