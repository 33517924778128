import React from "react"

// Material-UI
import { withStyles } from "@material-ui/core/styles"

// Components
import Typography from "@components/Typography"

const styles = (theme) => ({
    root: {
        cursor: "pointer",
    },
})

export const handleJumpToAnchor = (event, anchorId, { block = "center" } = {}) => {
    const anchor = (event.target.ownerDocument || document).querySelector(anchorId)
    if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: block })
    }
}

const JumpToAnchorText = React.forwardRef(function JumpToAnchorText(props, ref) {
    const { classes, children, anchorId, ...others } = props

    return (
        <Typography
            ref={ref}
            className={classes.root}
            onClick={(event) => handleJumpToAnchor(event, anchorId)}
            {...others}
        >
            {children}
        </Typography>
    )
})

export default withStyles(styles)(JumpToAnchorText)
