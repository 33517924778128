import { auth, firestore } from "@lib/firebase"
import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"

// Custom hook to read auth record and user profile doc
export function useUserData() {
    const [user] = useAuthState(auth)
    const [status, setStatus] = useState(null)
    const [username, setUsername] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        // turn off realtime subscription
        let unsubscribe

        if (user != null) {
            const ref = firestore.collection("users").doc(user.uid)
            unsubscribe = ref.onSnapshot((doc) => {
                const { username, displayName = "", photoURL = "" } = doc.data() || {}
                setStatus(username ? "complete" : "partial")
                setUsername(username)
                setData({ displayName, photoURL })
            })
        } else {
            setUsername(null)
            setStatus(null)
            setData(null)
        }

        return unsubscribe
    }, [user])

    return { user, status, username, data }
}

export default useUserData
