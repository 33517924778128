import { firestore, increment, fromMillis, serverTimestamp } from "@lib/firebase"
import { docUserPostRef, docUserPost } from "@lib/data/postData"

// doc
export function docPostReactionRef(reaction) {
    if (typeof reaction === "string") {
        return firestore.doc(reaction)
    } else if (typeof reaction?.ref === "string") {
        return firestore.doc(reaction.ref)
    } else if (reaction?.ref) {
        return reaction.ref
    } else return reaction
}

export function docUserPostReaction(rid, pid, uid) {
    const reactionDoc = collectionUserPostReaction(pid, uid).doc(rid)
    return reactionDoc
}

// collection
export function collectionPostReaction() {
    const reactionsCollection = firestore.collectionGroup("reactions")
    return reactionsCollection
}

export function collectionUserPostReaction(pid, uid) {
    const reactionsCollection = docUserPost(pid, uid).collection("reactions")
    return reactionsCollection
}

// query
export function queryUserReactionsCollection(
    userReactionsCollection,
    uid,
    { limit = null } = {}
) {
    let userReactionsQuery = userReactionsCollection
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")

    if (limit) {
        userReactionsQuery = userReactionsQuery.limit(limit)
    }

    return userReactionsQuery
}

export function queryReactionsCollection(
    reactionCollection,
    { published = true, type = "user", visibility = "public", limit = null } = {}
) {
    let reactionsQuery = reactionCollection
        .where("published", "==", published)
        .where("visibility", "==", visibility)
        .where("type", "==", type)
        .orderBy("createdAt", "desc")

    if (limit) {
        reactionsQuery = reactionsQuery.limit(limit)
    }

    return reactionsQuery
}

// get
export async function getPostReactionsCollectionData(
    postDoc,
    {
        published = true,
        type = "user",
        visibility = "public",
        limit = null,
        json = false,
    } = {}
) {
    const converter = json ? reactionJSONConverter : reactionConverter

    const reactionsCollection = postDoc.collection("reactions").withConverter(converter)
    const reactionsQuery = queryReactionsCollection(reactionsCollection, {
        published,
        type,
        visibility,
        limit,
    })

    const reactions = (await reactionsQuery.get()).docs.map((reaction) => reaction.data())
    return reactions
}

export async function getUserReactionsCollectionData(
    uid,
    { limit = null, json = false } = {}
) {
    const converter = json ? reactionJSONConverter : reactionConverter

    const userReactionsCollection = collectionPostReaction().withConverter(converter)
    const reactionsQuery = queryUserReactionsCollection(userReactionsCollection, uid, {
        limit,
    })

    const reactions = (await reactionsQuery.get()).docs.map((reaction) => reaction.data())
    return reactions
}

export const genReactionData = (authorData, reactionData, postData) => {
    const { pid, uid, posterName, contentUrl } = postData

    const {
        name,
        title,
        note,
        startRelative,
        durationRelative,
        startSeconds,
        durationSeconds,
        contentDuration,
    } = reactionData

    const { authorName, authorImageUrl } = authorData

    const type = "user"

    let data = {
        authorName: authorName,
        authorImageUrl: authorImageUrl,

        pid: pid,
        puid: uid,

        posterName: posterName,
        contentUrl: contentUrl,

        contentDuration: contentDuration ?? null,

        name: name,
        title: title,
        note: note,

        startRelative: startRelative ?? 0.0,
        durationRelative: durationRelative ?? null,

        startSeconds: startSeconds ?? 0,
        durationSeconds: durationSeconds ?? null,

        published: true,
        visibility: "public",

        type: type,

        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),

        deleted: false,
    }

    return data
}

// add
export const addPostReaction = async (authorData, reactionData, post, uid) => {
    const postDoc = docUserPostRef(post)
    const reactionDoc = postDoc.collection("reactions").doc()
    const rid = reactionDoc.id

    const batch = firestore.batch()
    batch.update(postDoc, { reactionCount: increment(1) })

    const data = genReactionData(authorData, reactionData, post.data)

    data["uid"] = uid
    ;(data["rid"] = rid), (data["postRef"] = postDoc)

    batch.set(reactionDoc, data)

    await batch.commit()

    return { ref: reactionDoc, data: data }
}

export const addPostReactions = async (authorData, reactionsData, post, uid) => {
    const postDoc = docUserPostRef(post)

    const numOfReactions = reactionsData.length

    const batch = firestore.batch()
    batch.update(postDoc, { reactionCount: increment(numOfReactions) })

    reactionsData.forEach((reactionData) => {
        const reactionDoc = postDoc.collection("reactions").doc()
        const rid = reactionDoc.id

        const data = genReactionData(authorData, reactionData, post.data)

        data["uid"] = uid
        data["rid"] = rid
        data["postRef"] = postDoc

        batch.set(reactionDoc, data)
    })

    await batch.commit()

    return { ref: null, data: null }
}
// clone

// update
export const updatePostReaction = async (data, reactionDoc) => {
    const batch = firestore.batch()

    batch.update(reactionDoc, {
        ...data,
        updatedAt: serverTimestamp(),
    })

    await batch.commit()

    return true
}

// remove
export const removePostReaction = async (reaction) => {
    const batch = firestore.batch()

    const postDoc = docUserPostRef(reaction.data.postRef)
    const reactionDoc = docPostReactionRef(reaction)

    batch.update(postDoc, { reactionCount: increment(-1) })

    batch.delete(reactionDoc)

    await batch.commit()

    return true
}

export const reactionConverter = {
    toFirestore: function (reaction) {
        return {
            ...reaction.data,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)
        return {
            ref: snapshot.ref,
            data: { ...data },
        }
    },
}
// Firestore data converter
export const reactionJSONConverter = {
    toFirestore: function (reaction) {
        let { createdAt, updatedAt } = reaction.data

        createdAt = typeof createdAt === "number" ? fromMillis(createdAt) : createdAt
        updatedAt = typeof updatedAt === "number" ? fromMillis(updatedAt) : updatedAt

        return {
            ...reaction.data,
            // Must convert postRef to ref
            postRef: docUserPostRef(reaction.data.postRef),
            // Must convert to milliseconds
            createdAt: createdAt,
            updatedAt: updatedAt,
        }
    },
    fromFirestore: function (snapshot, options) {
        let data = snapshot.data(options)

        data = {
            ...data,
            // Must convert postRef to path string
            postRef: data.postRef.path,
            // Gotcha! firestore timestamp NOT serializable to JSON.
            // Must convert to milliseconds
            createdAt: data.createdAt?.toMillis(),
            updatedAt: data.updatedAt?.toMillis(),
        }
        return {
            ref: snapshot.ref.path,
            data: { ...data },
        }
    },
}

// transformer for useDocumentData / useCollectionData
export function reactionTransformer(val) {
    return {
        data: { ...val },
    }
}

export function reactionJSONTransformer(val) {
    val = {
        ...val,
        // Must convert postRef to path string
        postRef: val.postRef.path,

        // Gotcha! firestore timestamp NOT serializable to JSON.
        // Must convert to milliseconds
        createdAt: val.createdAt?.toMillis(),
        updatedAt: val.updatedAt?.toMillis(),
    }
    return reactionTransformer(val)
}

export function optionsReaction(json = false) {
    const transform = json ? reactionJSONTransformer : reactionTransformer
    const refField = json ? null : "ref"
    return {
        refField,
        transform,
    }
}
