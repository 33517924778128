import React from "react"
import { useFormContext, Controller } from "react-hook-form"

// Material UI
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

// Components
import Typography from "@components/Typography"

function MuiCheckbox(props) {
    const { label, name, color, variant, ...others } = props
    return (
        <FormControlLabel
            control={<Checkbox name={name} {...others} />}
            label={
                <Typography color={color} variant={variant}>
                    {label}
                </Typography>
            }
        />
    )
}

function FormCheckbox(props) {
    const { control } = useFormContext()
    const { name, label, variant, ...others } = props
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={false}
            render={({
                field: { value, ref, ...fieldOthers },
                fieldState: { invalid, isTouched, isDirty, error },
                formState: { isValidating },
            }) => (
                <MuiCheckbox
                    checked={value}
                    inputRef={ref}
                    {...fieldOthers}
                    label={invalid ? error?.message : label}
                    color={invalid ? "secondary" : "primary"}
                    variant={variant}
                    {...others}
                />
            )}
        />
    )
}

export default FormCheckbox
