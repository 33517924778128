import axios from "axios"

export async function getNoembedInfo(url) {
    const { data } = await axios.get(`https://noembed.com/embed?url=${url}`)

    return {
        contentUrl: data?.url || null,

        title: data?.title || null,
        imageUrl: data?.thumbnail_url || null,
        imageUrlSource: data?.provider_name || null,

        contentType: data?.type || null,
        contentAuthorName: data?.author_name || null,
        contentAuthorUrl: data?.author_url || null,

        contentProviderName: data?.provider_name || null,
        contentProviderUrl: data?.provider_url || null,
    }
}
